import React, { useEffect, useState } from 'react';

// material-ui
import {
    Grid,
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    CardContent,
    Fab,
    Tooltip,
    Button,
    TextField,
    Chip,
    Popover,
    Typography,
    Autocomplete,
    MenuItem,
    Select,
    InputAdornment,
    Stack,
    IconButton,
    Drawer,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Dialog,
    useTheme,
    Slide
} from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// assets
import {
    fetchMerchants,
    fetchPayoutTransactionsByFilter,
    fetchPayoutTransactions,
    fetchPG,
    fetchActiveMerchants,
    fetchAllRemarks
} from 'utils/api';
import TableHeader from 'ui-component/TableHead';
import { CurrencyRupee, RefreshOutlined, Settings, Edit, Close } from '@mui/icons-material';
import { openSnackbar } from 'store/slices/snackbar';
import { useDispatch } from 'react-redux';
import Loader from 'ui-component/Loader';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import { gridSpacing } from 'store/constant';
import HoverDataCard from 'ui-component/cards/HoverDataCard';
// table header options

import SearchIcon from '@mui/icons-material/Search';
import { ISTDate, PAYOUT_STATUSES, ROLE_ADMIN, ROLE_SUPERADMIN, TXN_STATUSES } from 'constant';
import ConfirmationModal from 'ui-component/Modal';
import axiosServices from 'utils/axios';
import Config from './Config';
import UpdatePayout from './UpdatePayout';
import useAuth from 'hooks/useAuth';
import axiosServicesNew from 'utils/axios_new';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const headCells = [
    {
        id: 'action',
        align: 'center'
    },
    {
        id: 'order',
        numeric: false,
        label: 'Order',
        align: 'left'
    },
    {
        id: 'pg',
        numeric: true,
        label: 'Bank',
        align: 'left'
    },
    {
        id: 'merchantName',
        numeric: true,
        label: 'Client',
        align: 'left'
    },
    {
        id: 'transactionStatus',
        numeric: true,
        label: 'Status',
        align: 'center'
    },
    {
        id: 'type',
        numeric: true,
        label: 'Type',
        align: 'center'
    },
    {
        id: 'amount',
        numeric: true,
        label: 'Amount',
        align: 'left'
    },
    {
        id: 'pg',
        numeric: true,
        label: 'PG',
        align: 'left'
    },
    {
        id: 'createdAt',
        numeric: true,
        label: 'Date',
        align: 'center'
    },
    {
        id: 'udf',
        numeric: true,
        label: 'UDF',
        align: 'left'
    },
    {
        id: 'customer',
        numeric: true,
        label: 'Customer',
        align: 'left'
    },
    {
        id: 'process',
        numeric: true,
        label: 'Action',
        align: 'center'
    }
];

const Payout = () => {
    const dispatch = useDispatch();
    const { user } = useAuth();
    const theme = useTheme();

    const currentDate = new Date(); // ISTDate;
    const [rows, setRows] = React.useState([]);
    const [allData, setAllData] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const [details, setDetails] = React.useState(false);
    const [update, setUpdate] = React.useState();
    const [config, setConfig] = React.useState(false);

    const [selectedStatus, setSelectedStatus] = React.useState();

    const [startDate, setStartDate] = React.useState(currentDate);
    const [endDate, setEndDate] = React.useState(currentDate);
    const [value, setValue] = React.useState();
    const [selectedValue, setSelectedValue] = React.useState('payoutId');

    const [selectedTxn, setSelectedTxn] = React.useState();
    const [selectedAccount, setSelectedAccount] = React.useState();
    const [selectedPG, setSelectedPG] = React.useState();

    const [type, setType] = useState();

    const [merchants, setMerchants] = useState();

    const [selectedMerchant, setSelectedMerchant] = useState();

    const [pgs, setPGs] = useState([]);
    const [pgAccounts, setPgAccounts] = useState([]);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);

    const [anchorEl, setAnchorEl] = React.useState();

    const [remark, setRemark] = React.useState();
    const [remarks, setRemarks] = React.useState([]);
    const [allMerchant, setAllMerchant] = React.useState([]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const fetchMerchantFromAPI = async () => {
        try {
            let result = await fetchActiveMerchants();
            result = result.map((r) => ({
                id: r.merchantId || '',
                label: r.name || ''
            }));
            setAllMerchant(result);
            setMerchants(result);
        } catch (error) {
            console.error(error);
        }
        try {
            let result = await fetchPG();
            result = result.map(({ pgLabel, pgLabelId }) => ({
                id: pgLabelId,
                label: `${pgLabel}`
            }));
            setPGs(result);
        } catch (error) {
            console.error(error);
        }
        try {
            let result = await fetchAllRemarks();
            result = result?.map((r) => ({
                id: r || '',
                label: r || ''
            }));
            setRemarks(result);
        } catch (error) {
            console.error(error);
        }
    };

    React.useEffect(() => {
        fetchMerchantFromAPI();
    }, []);

    React.useEffect(() => {
        if (selectedPG) {
            axiosServices.get(`getAllPayoutMetaForFilter/${selectedPG?.label}`).then((res) => {
                setPgAccounts(res.data?.data || []);
            });
        }
    }, [selectedPG]);

    const fetchDataFromAPI = async (page, rowsPerPage, startDate, endDate) => {
        setLoading(true);
        try {
            const result = await fetchPayoutTransactions(
                page,
                rowsPerPage,
                new Date(startDate).toISOString(),
                new Date(endDate).toISOString()
            );
            setLoading(false);
            setAllData(result?.data);
            setRows(result?.data?.transactionList || []);
        } catch (error) {
            setLoading(false);
        }
    };

    const fetchSsTransactionsByFilter = async (searchObj) => {
        if (
            searchObj?.selectedValue === 'payoutId' &&
            !searchObj.value &&
            !selectedMerchant &&
            !selectedStatus &&
            !selectedPG &&
            !selectedAccount
        ) {
            fetchDataFromAPI(page, rowsPerPage, new Date(startDate).toISOString(), new Date(endDate).toISOString());
        } else {
            try {
                setLoading(true);

                const type = searchObj.selectedValue;
                // if (selectedMerchant?.id && selectedBank?.id) {
                //     type = 'payoutLabelIdAndMerchantId';
                // } else if (selectedBank?.id) {
                //     type = 'payoutLabelId';
                // } else if (selectedMerchant?.id) {
                //     type = 'merchantId';
                // }
                // const params = {
                //     ssType: type,
                //     ssValue: searchObj.value,
                //     pageIndex: page,
                //     pageSize: rowsPerPage,
                //     merchantId: selectedMerchant?.id,
                //     payoutLabelId: selectedBank?.id,
                //     startDate,
                //     endDate
                // };

                const params = {
                    customerOrderId: type === 'customerOrderId' ? value : '',
                    orderId: type === 'orderId' ? value : '',
                    amount: type === 'amount' ? value : '',
                    transactionId: type === 'transactionId' ? value : '',
                    customerId: type === 'customerId' ? value : '',
                    payoutId: type === 'payoutId' ? value : '',
                    utr: type === 'utr' ? value : '',
                    udf1: type === 'udf1' ? value : '',
                    udf2: type === 'udf2' ? value : '',
                    udf3: type === 'udf3' ? value : '',
                    udf4: type === 'udf4' ? value : '',
                    udf5: type === 'udf5' ? value : '',
                    merchantIdList: selectedMerchant?.length ? selectedMerchant?.map((m) => m.id) : [],
                    transactionStatusList: selectedStatus?.length ? selectedStatus : [],
                    pgAccountIds: selectedAccount?.length ? selectedAccount?.map((a) => a.account) : [],
                    pgName: selectedPG?.label,
                    startDateTime: startDate,
                    endDateTime: endDate,
                    remark: remark?.label || ''
                };

                const results = await fetchPayoutTransactionsByFilter(params, page, rowsPerPage);
                setLoading(false);

                if (results) {
                    setAllData(results?.data);
                    setRows(results?.data?.transactionList || []);
                }
            } catch (error) {
                setLoading(false);

                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Something went wrong',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: true
                    })
                );
            }
        }
    };

    React.useEffect(() => {
        fetchSsTransactionsByFilter({ value, selectedValue });
    }, [page, rowsPerPage]);

    React.useEffect(() => {
        if (remark?.label) {
            axiosServices.get(`getAllActiveMerchantByRemark/${remark?.label}`).then((r) => {
                const result = r?.data?.data || [];
                const mer = result?.map((r) => ({
                    id: r.merchantId || '',
                    label: r.name || ''
                }));
                setMerchants(mer || []);
            });
        } else {
            setMerchants(allMerchant);
        }
    }, [remark]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event?.target.value, 10));
        setPage(0);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleSearch = () => {
        fetchSsTransactionsByFilter({ value, selectedValue });
    };

    const clearSearch = () => {
        window.location.reload();
        // setSelectedValue('payoutId');
        // setValue('');
        // setPage(0);
        // setRowsPerPage(50);
        // setSelectedMerchant();
        // setSelectedBank();
        // fetchDataFromAPI(0, 50, startDate, endDate);
    };

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    const handleUpdateDialog = async () => {
        setUpdate(false);
        clearSearch();
    };

    const onSearch = (event) => {
        if (selectedValue === 'utrNo') {
            if (event.target.value?.length <= 12) {
                setValue(event.target.value);
            }
        } else {
            setValue(event.target.value);
        }
    };

    const resendWebhook = () => {
        if (!selectedTxn) return;
        setLoading(true);
        axiosServices
            .get(`initiatePayoutWebhook/${selectedTxn?.payoutId}`)
            .then((res) => {
                setLoading(false);
                setSelectedTxn('');
                setType();
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Webhook request processed',
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: true
                    })
                );
            })
            .catch((e) => {
                setLoading(false);
                dispatch(
                    openSnackbar({
                        open: true,
                        message: e.message || 'Something went wrong',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: true
                    })
                );
            });
    };

    const onRefresh = () => {
        setRows([]);
        fetchSsTransactionsByFilter({ value, selectedValue });
    };

    const reversePayout = () => {
        if (!selectedTxn) return;
        setLoading(true);
        axiosServices
            .post(`reversePayoutTransactionStatus/${selectedTxn?.payoutId}?remark=Transaction Reversed`)
            .then((res) => {
                onRefresh();
                setLoading(false);
                setSelectedTxn('');
                setType();
                dispatch(
                    openSnackbar({
                        open: true,
                        message: res.data.message || 'Reversed Success',
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: true
                    })
                );
            })
            .catch((e) => {
                setLoading(false);
                dispatch(
                    openSnackbar({
                        open: true,
                        message: e.message || 'Something went wrong',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: true
                    })
                );
            });
    };

    const failedPayout = () => {
        if (!selectedTxn) return;
        setLoading(true);
        axiosServices
            .post(`reversePayoutTransactionStatus/${selectedTxn?.payoutId}?remark=Transaction Failed`)
            .then((res) => {
                onRefresh();
                setLoading(false);
                setSelectedTxn('');
                setType();
                dispatch(
                    openSnackbar({
                        open: true,
                        message: res.data.message || 'Failed Success',
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: true
                    })
                );
            })
            .catch((e) => {
                setLoading(false);
                dispatch(
                    openSnackbar({
                        open: true,
                        message: e.message || 'Something went wrong',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: true
                    })
                );
            });
    };

    const handleGenerateReport = () => {
        setLoading(true);
        const date1 = new Date(startDate).getTime();
        const date2 = new Date(endDate).getTime();
        if (date1 <= date2) {
            const type = selectedValue;

            const params = {
                customerOrderId: type === 'customerOrderId' ? value : '',
                orderId: type === 'orderId' ? value : '',
                amount: type === 'amount' ? value : '',
                transactionId: type === 'transactionId' ? value : '',
                payoutId: type === 'payoutId' ? value : '',
                customerId: type === 'customerId' ? value : '',
                utr: type === 'utr' ? value : '',
                udf1: type === 'udf1' ? value : '',
                udf2: type === 'udf2' ? value : '',
                udf3: type === 'udf3' ? value : '',
                udf4: type === 'udf4' ? value : '',
                udf5: type === 'udf5' ? value : '',
                merchantIdList: selectedMerchant?.length ? selectedMerchant?.map((m) => m.id) : [],
                transactionStatusList: selectedStatus?.length ? selectedStatus : [],
                pgAccountIds: selectedAccount?.length ? selectedAccount?.map((a) => a.account) : [],
                pgName: selectedPG?.label,
                startDateTime: startDate,
                endDateTime: endDate
            };
            axiosServicesNew
                .post(`newPayoutReport`, params)
                .then((res) => {
                    setLoading(false);
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: res?.data?.data || 'Report Generation In progress',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: true
                        })
                    );
                })
                .catch((e) => {
                    setLoading(false);
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: e.message,
                            variant: 'alert',
                            alert: {
                                color: 'error'
                            },
                            close: true
                        })
                    );
                });
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'End date cannot be less than Start date',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
        }
    };

    return (
        <MainCard
            title={
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>Payout</div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Tooltip title="Refresh">
                            <RefreshOutlined onClick={onRefresh} style={{ cursor: 'pointer', marginRight: 10 }} />
                        </Tooltip>

                        <Button size="small" variant="outlined" sx={{ mr: 2 }} onClick={handleGenerateReport}>
                            Generate Report
                        </Button>
                        {(user?.role === ROLE_SUPERADMIN || user?.role === ROLE_ADMIN) && (
                            <Button size="small" variant="contained" onClick={() => setConfig(true)}>
                                Configuration
                            </Button>
                        )}
                    </div>
                </div>
            }
            content={false}
        >
            <Config open={config} handleCloseDialog={setConfig} />
            <UpdatePayout open={update} handleUpdateDialog={handleUpdateDialog} handleCloseDialog={setUpdate} />

            {loading && <Loader />}
            <CardContent sx={{ padding: '20px 0px 0px 20px' }}>
                <Grid container style={{ display: 'flex', justifyContent: 'space-between' }} alignItems="center">
                    <Grid
                        container
                        xs={12}
                        sm={12}
                        style={{ display: 'flex', justifyContent: 'start', flexWrap: 'wrap' }}
                        alignItems="center"
                        spacing={2}
                    >
                        <Select
                            placeholder="Select"
                            value={selectedValue}
                            onChange={handleChange}
                            sx={{ width: 140, mr: 1, ml: 2, mt: 2 }}
                            size="small"
                        >
                            <MenuItem value="payoutId">payout ID</MenuItem>
                            <MenuItem value="utr">UTR</MenuItem>
                            <MenuItem value="amount">Amount</MenuItem>
                            <MenuItem value="orderId">Order ID</MenuItem>
                            <MenuItem value="customerId">Customer ID</MenuItem>
                            <MenuItem value="customerOrderId">Customer Order ID</MenuItem>
                            <MenuItem value="udf1">UDF1</MenuItem>
                            <MenuItem value="udf2">UDF2</MenuItem>
                            <MenuItem value="udf3">UDF3</MenuItem>
                            <MenuItem value="udf4">UDF4</MenuItem>
                            <MenuItem value="udf5">UDF5</MenuItem>
                        </Select>

                        <TextField
                            sx={{ width: 150, mr: 1, ml: 1, mt: 2 }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment sx={{ mr: 0 }} position="start">
                                        <SearchIcon fontSize="small" />
                                    </InputAdornment>
                                )
                            }}
                            onKeyDown={(ev) => {
                                if (ev.key === 'Enter') {
                                    handleSearch();
                                }
                            }}
                            onChange={onSearch}
                            type={selectedValue === 'amount' ? 'number' : 'string'}
                            placeholder="Enter Value"
                            value={value}
                            size="small"
                        />
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                value={startDate}
                                onChange={(value) => {
                                    setStartDate(value);
                                }}
                                maxDate={ISTDate}
                                renderInput={(params) => <TextField sx={{ width: 160, mr: 1, ml: 1, mt: 2 }} size="small" {...params} />}
                                label="Start Date"
                            />
                            <DateTimePicker
                                value={endDate}
                                minDate={startDate}
                                maxDate={ISTDate}
                                onChange={(value) => {
                                    setEndDate(value);
                                }}
                                renderInput={(params) => <TextField sx={{ width: 160, mr: 1, ml: 1, mt: 2 }} size="small" {...params} />}
                                label="End Date"
                            />
                        </LocalizationProvider>
                        <Autocomplete
                            multiple
                            id="status-select"
                            limitTags={1}
                            size="small"
                            disableCloseOnSelect
                            options={PAYOUT_STATUSES || []}
                            onChange={(e, value) => {
                                setSelectedStatus(value);
                            }}
                            sx={{ width: 220, mr: 1, ml: 1, mt: 2 }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    onChange={(e, value) => {
                                        setSelectedStatus(value);
                                    }}
                                    label="Status"
                                    size="small"
                                />
                            )}
                        />
                    </Grid>
                </Grid>

                <Grid sx={{ mt: 2 }} container justifyContent="start" alignItems="center" spacing={2}>
                    <Autocomplete
                        id="remarkId"
                        className="remark-select"
                        name="remarkId"
                        options={remarks?.length ? remarks : []}
                        onChange={(e, value) => {
                            setRemark(value);
                        }}
                        sx={{ width: 180, mr: 1, ml: 2, mt: 1 }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                onChange={(e, value) => {
                                    setRemark(value);
                                }}
                                size="small"
                                label="Remark"
                                variant="outlined"
                                fullWidth
                            />
                        )}
                    />
                    <Autocomplete
                        id="merchant-select"
                        multiple
                        limitTags={1}
                        options={merchants || []}
                        onChange={(e, value) => {
                            setSelectedMerchant(value);
                        }}
                        sx={{ width: 220, mr: 2, ml: 2, mt: 1 }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                onChange={(e, value) => {
                                    setSelectedMerchant(value);
                                }}
                                label="Merchant"
                                size="small"
                            />
                        )}
                    />

                    <Autocomplete
                        id="pg-account-select"
                        size="small"
                        disableCloseOnSelect
                        options={pgs || []}
                        onChange={(e, value) => {
                            setSelectedPG(value);
                        }}
                        sx={{ width: 130, mr: 2, ml: 2, mt: 1 }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                onChange={(e, value) => {
                                    setSelectedPG(value);
                                }}
                                label="PG"
                                size="small"
                            />
                        )}
                    />
                    <Autocomplete
                        id="pg-select"
                        size="small"
                        multiple
                        limitTags={1}
                        disableCloseOnSelect
                        options={pgAccounts || []}
                        onChange={(e, value) => {
                            setSelectedAccount(value);
                        }}
                        sx={{ width: 200, mr: 2, ml: 2, mt: 1 }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                onChange={(e, value) => {
                                    setSelectedAccount(value);
                                }}
                                label="PG Accounts"
                                size="small"
                            />
                        )}
                    />

                    {/* <Autocomplete
                        id="bank-select"
                        options={banks || []}
                        onChange={(e, value) => {
                            setSelectedBank(value);
                        }}
                        sx={{ width: 210, mr: 2, ml: 2, mt: 1 }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                onChange={(e, value) => {
                                    setSelectedBank(value);
                                }}
                                label="PG"
                                size="small"
                            />
                        )}
                    /> */}
                    <Button size="small" variant="contained" sx={{ mr: '1rem' }} onClick={handleSearch}>
                        Apply
                    </Button>
                    <Button size="small" variant="contained" color="secondary" onClick={clearSearch}>
                        Clear
                    </Button>
                </Grid>
            </CardContent>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12} lg={3} sm={6}>
                    <HoverDataCard title="TOTAL PAYOUT" primary={allData?.totalSize || 0} />
                </Grid>

                <Grid item xs={12} lg={3} sm={6}>
                    <HoverDataCard title="PAYOUT AMOUNT" iconPrimary={CurrencyRupee} primary={allData?.totalPayment || 0} />
                </Grid>
                <Grid item xs={12} lg={3} sm={6}>
                    <HoverDataCard title="TOTAL PAYOUT AMOUNT" iconPrimary={CurrencyRupee} primary={allData?.totalSuccessAmount || 0} />
                </Grid>
                <Grid item xs={12} lg={3} sm={6}>
                    <HoverDataCard title="TOTAL PAYOUT FEES" iconPrimary={CurrencyRupee} primary={0} />
                </Grid>
            </Grid>
            <TableContainer sx={{ minWidth: 750, height: 500 }}>
                <Table stickyHeader aria-labelledby="tableTitle">
                    <TableHeader columns={headCells} />
                    <TableBody>
                        {rows?.map((row, index) => {
                            /** Make sure no display bugs if row isn't an OrderData object */
                            if (typeof row === 'number') return null;
                            const labelId = `enhanced-table-checkbox-${index}`;

                            let variant = 'info';

                            if (row.transactionStatus === 'SUCCESS') {
                                variant = 'success';
                            } else if (row.transactionStatus === 'FAILED') {
                                variant = 'error';
                            } else if (row.transactionStatus === 'PROCESSING') {
                                variant = 'warning';
                            } else {
                                variant = 'info';
                            }

                            return (
                                <TableRow hover>
                                    <TableCell align="center">
                                        <div style={{ minWidth: 40 }}>
                                            <Settings
                                                onClick={(e) => {
                                                    handleClick(e);
                                                    setSelectedTxn(row);
                                                }}
                                            />
                                        </div>
                                    </TableCell>

                                    {selectedTxn?.transactionStatus !== 'FAILED' ? (
                                        <Popover
                                            id={id}
                                            open={open}
                                            anchorEl={anchorEl}
                                            onClose={handleClose}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left'
                                            }}
                                        >
                                            <Typography
                                                onClick={() => {
                                                    handleClose();
                                                    setType('webhook');
                                                }}
                                                sx={{ p: 1, fontSize: 10, cursor: 'pointer' }}
                                            >
                                                Resend Webhook
                                            </Typography>
                                            <Typography
                                                onClick={() => {
                                                    handleClose();
                                                    setType('reverse');
                                                }}
                                                sx={{ p: 1, fontSize: 10, cursor: 'pointer' }}
                                            >
                                                Reversed or Failed Payout
                                            </Typography>
                                        </Popover>
                                    ) : (
                                        <Popover
                                            id={id}
                                            open={open}
                                            anchorEl={anchorEl}
                                            onClose={handleClose}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left'
                                            }}
                                        >
                                            <Typography
                                                onClick={() => {
                                                    handleClose();
                                                    setType('webhook');
                                                }}
                                                sx={{ p: 1, fontSize: 10, cursor: 'pointer' }}
                                            >
                                                Resend Webhook
                                            </Typography>
                                        </Popover>
                                    )}

                                    <TableCell>
                                        <div
                                            role="button"
                                            tabIndex="0"
                                            onKeyDown={null}
                                            onClick={() => setDetails(row)}
                                            style={{ minWidth: 200, fontWeight: 500, color: 'blue', cursor: 'pointer' }}
                                        >
                                            ID: {row.payoutId}
                                        </div>
                                        <div>Order: {row.customerOrderId}</div>
                                        <div>PG Ref: {row.pgRef}</div>
                                        <div>UTR: {row.utr}</div>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ minWidth: 220 }}>Account: {row.accountNumber}</div>
                                        <div>Bank: {row.bankName}</div>
                                        <div>IFSC: {row.ifscCode}</div>
                                        <div>Account Holder: {row.accountHolderName}</div>
                                    </TableCell>
                                    <TableCell>
                                        <div>{row.merchantName}</div>
                                        <div>{row.merchantId}</div>
                                        <div>{row.remark}</div>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Chip size="small" color={variant} label={row.transactionStatus} />
                                    </TableCell>
                                    <TableCell align="center">
                                        <div style={{ minWidth: 100 }}>{row.availableMethod}</div>
                                        <div>{row.paymentFlow}</div>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ minWidth: 100 }}>Rs. {row.amount || '-'}</div>
                                    </TableCell>

                                    <TableCell>
                                        <div style={{ minWidth: 150 }}>
                                            <div>{row.pgName}</div>
                                            <div>
                                                {row.payoutLabel} - {row.payoutAccountId}
                                            </div>
                                        </div>
                                    </TableCell>

                                    <TableCell align="center" style={{ fontWeight: 500 }} component="th" id={labelId} scope="row">
                                        <div style={{ minWidth: 220 }}>
                                            Created: {row.createdAt ? new Date(row.createdAt).toLocaleString() : ''}
                                        </div>
                                        <div>Updated: {row.updatedAt ? new Date(row.updatedAt).toLocaleString() : ''}</div>
                                        <div>Pending: {row.pendingAt ? new Date(row.pendingAt).toLocaleString() : ''}</div>
                                    </TableCell>

                                    <TableCell>
                                        <div style={{ minWidth: 100 }}>UDF1: {row.udf1}</div>
                                        <div>UDF2: {row.udf2}</div>
                                        <div>UDF3: {row.udf3}</div>
                                        <div>UDF4: {row.udf4}</div>
                                        <div>UDF5: {row.udf5}</div>
                                    </TableCell>

                                    <TableCell>
                                        <div style={{ minWidth: 100 }}>
                                            <div>ID: {row.customerId}</div>
                                            <div>Name: {row.customerName}</div>
                                        </div>
                                    </TableCell>

                                    {/* <TableCell align="center">
                                        <Stack direction="row" justifyContent="center" alignItems="center">
                                            <Tooltip placement="top" title="Edit">
                                                <IconButton color="primary" aria-label="settings">
                                                    <SettingsApplications onClick={() => {}} />
                                                </IconButton>
                                            </Tooltip>
                                        </Stack>
                                    </TableCell> */}
                                    <TableCell>
                                        <Stack style={{ minWidth: 200 }}>
                                            {/* <Tooltip placement="top" title="Test">
                                                <IconButton color="primary" aria-label="test" size="large">
                                                    <Verified onClick={() => setTestOpen(row)} sx={{ fontSize: '1.1rem' }} />
                                                </IconButton>
                                            </Tooltip> */}
                                            <Tooltip placement="top" title="Process & Update">
                                                <IconButton color="primary" aria-label="Process & Update" size="large">
                                                    <Button
                                                        variant="contained"
                                                        size="small"
                                                        onClick={() => {
                                                            setUpdate(row);
                                                        }}
                                                    >
                                                        Process & Update
                                                    </Button>
                                                </IconButton>
                                            </Tooltip>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                        {/* {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: 53 * emptyRows
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )} */}
                    </TableBody>
                </Table>
            </TableContainer>

            {type === 'webhook' && (
                <ConfirmationModal
                    loading={loading}
                    open={selectedTxn}
                    title="Resend Webhook"
                    subTitle="Are you sure, you want to resend webhook"
                    handleClose={() => {
                        setSelectedTxn();
                        setType();
                    }}
                    handleSubmit={resendWebhook}
                />
            )}

            {type === 'reverse' && (
                <Dialog
                    open={selectedTxn}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => {
                        setSelectedTxn();
                        setType();
                    }}
                    aria-labelledby="alert-dialog-slide-title1"
                    aria-describedby="alert-dialog-slide-description1"
                >
                    {selectedTxn && (
                        <>
                            <DialogTitle style={{ display: 'flex', justifyContent: 'space-between' }} id="alert-dialog-slide-title1">
                                <div>Reverse/Failed Payout</div>
                                <div>
                                    <Close
                                        onClick={() => {
                                            setSelectedTxn();
                                            setType();
                                        }}
                                    />
                                </div>
                            </DialogTitle>

                            <DialogContent>
                                <DialogContentText id="alert-dialog-slide-description1">
                                    <Typography variant="body2" component="span">
                                        Are you sure, you want to reverse or failed the Payout
                                    </Typography>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions sx={{ pr: 2.5 }}>
                                <Button
                                    disabled={loading}
                                    sx={{ color: theme.palette.error.dark, borderColor: theme.palette.error.dark }}
                                    onClick={reversePayout}
                                    color="secondary"
                                >
                                    Reverse
                                </Button>
                                <Button disabled={loading} variant="contained" size="small" onClick={failedPayout}>
                                    Failed
                                </Button>
                            </DialogActions>
                        </>
                    )}
                </Dialog>
                // <ConfirmationModal
                //     loading={loading}
                //     open={selectedTxn}
                //     title="Reverse Payout"
                //     subTitle="Are you sure, you want to reverse or failed the Payout"
                //     handleClose={() => {
                //         setSelectedTxn();
                //         setType();
                //     }}
                //     handleSubmit={reversePayout}
                // />
            )}

            <Drawer anchor="right" open={details} onClose={() => setDetails(false)}>
                <div style={{ minWidth: 500 }}>
                    <div style={{ padding: '20px 10px', fontSize: 14, fontWeight: 500, border: '1px solid lightgray' }}>
                        Payout ID : {details?.payoutId}
                    </div>
                    <div style={{ padding: '20px 10px' }}>
                        <div style={{ fontWeight: 500, color: 'blue', fontSize: 14, marginBottom: 10 }}>Payout Detail</div>
                        <div style={{ display: 'flex', border: '1px solid lightgray', padding: 10 }}>
                            <div style={{ width: 150, fontWeight: 500 }}>Payout Amount</div>
                            <div>{details?.amount || '-'}</div>
                        </div>
                        <div style={{ display: 'flex', border: '1px solid lightgray', padding: 10 }}>
                            <div style={{ width: 150, fontWeight: 500 }}>Payout Fees</div>
                            <div>{details?.pgFees || '-'}</div>
                        </div>
                        <div style={{ display: 'flex', border: '1px solid lightgray', padding: 10 }}>
                            <div style={{ width: 150, fontWeight: 500 }}>Associate Fees</div>
                            <div>{details?.associateFees || '-'}</div>
                        </div>
                        <div style={{ display: 'flex', border: '1px solid lightgray', padding: 10 }}>
                            <div style={{ width: 150, fontWeight: 500 }}>Total Amount</div>
                            <div>{details?.payableAmount || '-'}</div>
                        </div>
                        <div style={{ display: 'flex', border: '1px solid lightgray', padding: 10 }}>
                            <div style={{ width: 150, fontWeight: 500 }}>Payout Status</div>
                            <div>{details?.transactionStatus || '-'}</div>
                        </div>
                        <div style={{ display: 'flex', border: '1px solid lightgray', padding: 10 }}>
                            <div style={{ width: 150, fontWeight: 500 }}>UTR</div>
                            <div>{details?.utr || '-'}</div>
                        </div>
                        <div style={{ display: 'flex', border: '1px solid lightgray', padding: 10 }}>
                            <div style={{ width: 150, fontWeight: 500 }}>Payout Type</div>
                            <div>{details?.availableMethod || '-'}</div>
                        </div>
                        <div style={{ display: 'flex', border: '1px solid lightgray', padding: 10 }}>
                            <div style={{ width: 150, fontWeight: 500 }}>Vpa Address</div>
                            <div>{details?.vpaAddress || '-'}</div>
                        </div>
                        <div style={{ display: 'flex', border: '1px solid lightgray', padding: 10 }}>
                            <div style={{ width: 150, fontWeight: 500 }}>Internal Status</div>
                            <div>{details?.internalStatus || '-'}</div>
                        </div>
                        {/* <div style={{ display: 'flex' }}>
                            <div style={{ width: 150, fontweight: 500 }}>Is Webhook Called</div>
                            <div>{details?.customerIp || '-'}</div>
                        </div> */}
                        <div
                            style={{
                                display: 'flex',
                                border: '1px solid lightgray',
                                padding: 10
                            }}
                        >
                            <div style={{ width: 150, fontWeight: 500 }}>Payout By</div>
                            <div>{details?.payoutBy || '-'}</div>
                        </div>
                    </div>
                    <div style={{ padding: '20px 10px' }}>
                        <div style={{ fontWeight: 500, color: 'blue', fontSize: 14, marginBottom: 10 }}>Cusomer Detail</div>
                        <div style={{ display: 'flex', border: '1px solid lightgray', padding: 10 }}>
                            <div style={{ width: 150, fontWeight: 500 }}>Customer Name</div>
                            <div>{details?.customerName || '-'}</div>
                        </div>
                        <div style={{ display: 'flex', border: '1px solid lightgray', padding: 10 }}>
                            <div style={{ width: 150, fontWeight: 500 }}>Customer Email</div>
                            <div>{details?.customerEmail || '-'}</div>
                        </div>
                        <div style={{ display: 'flex', border: '1px solid lightgray', padding: 10 }}>
                            <div style={{ width: 150, fontWeight: 500 }}>Customer Phone</div>
                            <div>{details?.customerMobile || '-'}</div>
                        </div>
                        <div style={{ display: 'flex', border: '1px solid lightgray', padding: 10 }}>
                            <div style={{ width: 150, fontWeight: 500 }}>Customer IP</div>
                            <div>{details?.customerIp || '-'}</div>
                        </div>
                    </div>
                    <div style={{ padding: '20px 10px' }}>
                        <div style={{ fontWeight: 500, color: 'blue', fontSize: 14, marginBottom: 10 }}>PG Detail</div>
                        <div style={{ display: 'flex', border: '1px solid lightgray', padding: 10 }}>
                            <div style={{ width: 150, fontWeight: 500 }}>Pg Name</div>
                            <div>{details?.pgName || '-'}</div>
                        </div>
                        <div style={{ display: 'flex', border: '1px solid lightgray', padding: 10 }}>
                            <div style={{ width: 150, fontWeight: 500 }}>Pg RefId</div>
                            <div>{details?.pgRef || '-'}</div>
                        </div>
                        <div style={{ display: 'flex', border: '1px solid lightgray', padding: 10 }}>
                            <div style={{ width: 150, fontWeight: 500 }}>Pg Response Msg</div>
                            <div>{details?.pgResponseMessage || '-'}</div>
                        </div>
                        <div style={{ display: 'flex', border: '1px solid lightgray', padding: 10 }}>
                            <div style={{ width: 150, fontWeight: 500 }}>Pg Payout Date</div>
                            <div>{details?.payoutDate || '-'}</div>
                        </div>
                    </div>

                    <div style={{ padding: '20px 10px' }}>
                        <div style={{ fontWeight: 500, color: 'blue', fontSize: 14, marginBottom: 10 }}>Bank Detail</div>
                        <div style={{ display: 'flex', border: '1px solid lightgray', padding: 10 }}>
                            <div style={{ width: 150, fontWeight: 500 }}>Account Holder Name</div>
                            <div>{details?.accountHolderName || '-'}</div>
                        </div>
                        <div style={{ display: 'flex', border: '1px solid lightgray', padding: 10 }}>
                            <div style={{ width: 150, fontWeight: 500 }}>Bank Name</div>
                            <div>{details?.bankName || '-'}</div>
                        </div>
                        <div style={{ display: 'flex', border: '1px solid lightgray', padding: 10 }}>
                            <div style={{ width: 150, fontWeight: 500 }}>Bank Account</div>
                            <div>{details?.accountNumber || '-'}</div>
                        </div>
                        <div style={{ display: 'flex', border: '1px solid lightgray', padding: 10 }}>
                            <div style={{ width: 150, fontWeight: 500 }}>Ifsc Code</div>
                            <div>{details?.ifscCode || '-'}</div>
                        </div>
                    </div>
                </div>
            </Drawer>

            {/* table pagination */}
            <TablePagination
                rowsPerPageOptions={[50, 100, 250]}
                component="div"
                count={allData?.totalSize}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </MainCard>
    );
};

export default Payout;
